import React from "react";

export default function Allows() {
  return (
    <div>
      <section className="creators_3 creators_4 pb_80 xs_pb_40">
        <div className="container">
 
          <div className="creators_3_area">
            <div className="row justify-content-between">
              <div className="col-xl-5 col-lg-5 wow fadeInLeft">
                <div className="crators_3_img1">
                  <img
                    src="assets/img/enhancing-trading-experience-section-Image.png"
                    alt="Enhancing Trading Experience"
                    className="img-fluid w-100 e-height"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 my-auto wow fadeInRight">
                <div className="crators_3_text">
                  {/* <h6><span><i className="fal fa-expand-alt" /></span>Social Media</h6> */}
                  <a className="creators_3_title">
                    Enhancing Trading Experience
                  </a>
                  <p>
                    With our latest developments, We seek to enhance trading
                    experience in cryptocurrency and forex markets. Join us &
                    level up your trading game!
                  </p>
                  <a className="btn_primary home_page_3_btn" href="#">
                    Join Now
                    <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
       
        </div>
      </section>
    </div>
  );
}
