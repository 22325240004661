import React from "react";
const today = new Date();
const year = today.getFullYear();

export default function Footer() {
  return (
    <div>
      <footer className="footer footer_3 footer_4 pt_150 xs_pt_80">
        <div className="container">
          <div className="footer_top_area mb_110">
            <div
              className="price_btm mt_30 wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="price_btm_item">
                    <h4>
                      Ready to explore BrightBit? Join our community & stay up
                      to date!
                    </h4>
                    <h5> </h5>
                    {/* <p>Fusce vitae consectetur libero, at congue lorem. Aenean vehicula finibus quam tincidunt
          lacus iaculis Nullam pharetra metus velit.</p> */}
                    <a className="btn_primary mt-2" href="#">
                      {" "}
                      Community{" "}
                    </a>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="price_btm_img">
                    <img src="assets/img/join-our-community1.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_top_wrapper xs_mb_70">
            <div className="row justify-content-center">
              <div className="col-xl-4">
                <div className="footer_item footer_item_3">
                  <div className="d-flex justify-content-center">
                    <a href="#">
                      <img
                        src="assets/img/logo.png"
                        className="img-fluid img-footer"
                        alt="Footer Logo"
                      />
                    </a>
                  </div>
                  <p className="text-center">
                    Join our community & get latest updates!
                  </p>
                  <ul className="footer_social footer_social_3 d-flex justify-content-center">
                    <li>
                      <a href="#">
                        <i className="fa-brands fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer_btm mt_55">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="footer_btmItem footer_btmItem_3">
                    <p>© {year} BrightBit. All rights reserved.</p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="footer_btmItem footer_btmItem_3">
                    <ul className="d-flex gap-3 justify-content-end">
                      <li>
                        <a href="#">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="#">Term of Service</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
