import React from "react";

export default function New() {
  return (
    <div>
      <section
        className="generated_area pt_50 xs_pt_15 pb_0 xs_pb_80"
        style={{ background: "url(assets/img/introducing_brightbit_blockchain_bg.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12 wow fadeInUp">
              <div className="sec_title pb_55 position-relative z-1">
                <h2 className="text-white">
                  Introducing BrightBit
                  <span className="span-text"> Blockchain </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 col-md-12">
              <div className="generated_content position-relative">
                <div className="generated_text">
                  <h1 className="text-white">
                    Build & deploy seamless dApps on fastest blockchain network
                  </h1>
                  <p className="mt-4 text-white">
                    BrightBit blockchain is a fast, secure & scalable
                    distributed network that enables to launch seamless
                    decentralized applications (dApps). It can be used for
                    various purposes such as launching smart contracts, tokens,
                    NFTs & more!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12">
              <div className="tab-content" id="v-pills-tabContent">
                <div className="generated_img position-relative">
                  <img
                    src="assets/img/blockchain-new.png"
                    alt="play_win"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
