import React from "react";

export default function Rather() {
  return (
    <div>
      <section className="feature_2 pt_80 pb_140 xs_pt_70 xs_pb_40 bg-white">
        <div className="container pb-5">
          <div className="row justify-content-center">
            <div
              className="col-xl-6 wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="sec_title_2 mb_25">
                <h2>
                  We strives to seek
                  <br /> <span className="span-text"> excellence! </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-xl-12 col-md-12 wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="single_feature_2" id="About">
                <div className="d-flex align-items-center">
                  <span>
                    <img
                      src="assets/img/about.png"
                      alt="About"
                    
                      className="img-fluid img-a"
                    />
                  </span>
                  <a className="feature_2_title  ms-3 h6 text-black">About</a>
                </div>
                <p className="pb-3 text-black">
                  BrightBit is a forward-thinking platform dedicated to
                  enhancing the trading experience in both cryptocurrency and
                  forex markets. By leveraging cutting-edge technology,
                  innovative tools, and comprehensive market insights, We aim to
                  empower traders of all levels to navigate the complexities of
                  financial markets with confidence and ease.
                </p>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-12 wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="single_feature_2 card-he">
                <div className="d-flex align-items-center">
                  <span>
                    <img
                      src="assets/img/vision.png"
                      alt="Vision"
                      className="img-fluid w-100"
                    />
                  </span>
                  <a className="feature_2_title  ms-3 h6 text-black">Vision</a>
                </div>
                <p className="text-black">
                  Our vision is to empower traders worldwide with cutting-edge
                  technology and comprehensive market insights, transforming the
                  trading experience in cryptocurrency and forex markets into a
                  seamless, accessible, and rewarding journey for all.
                </p>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-12 wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="single_feature_2 card-he">
                <div className="d-flex align-items-center">
                  <span>
                    <img
                      src="assets/img/mission.png"
                      alt="Mission"
                      className="img-fluid w-100"
                    />
                  </span>
                  <a className="feature_2_title  ms-3 h6 text-black">Mission</a>
                </div>
                <p className="text-black">
                  Our mission is to enhance the trading experience by providing
                  innovative tools, advanced analytics, and personalized support
                  to traders at all levels. We strive to foster a transparent
                  and inclusive trading environment where every participant can
                  confidently navigate the complexities of the cryptocurrency
                  and forex markets, ultimately achieving their financial goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
