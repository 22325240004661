import React from "react";

export default function NFTs() {
  return (
    <div>
      <section className="creators_3 creators_4 pb_120 xs_pb_80">
        <div className="container">
          <div className="creators_3_area" id="NFTs">
            <div className="row justify-content-between">
              <div className="col-xl-5 col-lg-5 wow fadeInLeft">
                <div className="crators_3_img1">
                  <img
                    src="assets/img/global-grow-nfts.png"
                    alt=" Global Grow NFTs"
                    className="img-fluid w-100 h-511"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 my-auto wow fadeInRight">
                <div className="crators_3_text">
                  {/* <h6><span><i className="fal fa-expand-alt" /></span>Social Media</h6> */}
                  <a className="creators_3_title">BrightBit NFTs</a>
                  <p>
                    The unique NFT collection enables users to benefit with
                    additional rewards. Grab some NFTs and start bearing yields
                    now!{" "}
                  </p>
                  <a className="btn_primary home_page_3_btn" href="#">
                    NFTs
                    <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
