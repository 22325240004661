import React from "react";

export default function Currency() {
  return (
    <div>
      <section className="creators_3 creators_4 pb_20 xs_pb_0">
        <div className="container">
          <div className="creators_3_area bg-white" id="NBBT-TokenFTs">
            <div className="row justify-content-between">
              <div className="col-xl-7 col-lg-7 my-auto wow fadeInLeft">
                <div className="crators_3_text ml_120">
                  <a className="creators_3_title">
                    BBT – Native Currency For Transferring Value
                  </a>

                  <p>
                    BrightBit Token (BBT) is the native token that is used
                    across the whole ecosystem as a means of payments and value
                    transfers.
                  </p>
                  <p className="mb-0">
                    <span style={{ fontWeight: "600" }}> Chain: </span> BNB
                    Chain
                  </p>
                  <p className="mt-0" style={{ overflow: "auto hidden" }}>
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      Contract Address:
                    </span>{" "}
                    <a
                      className="text-black"
                      style={{ borderBottom: "1px solid black" }}
                      target="_blank"
                      href="https://bscscan.com/token/0x164b6f3f35d98214a592e4ba7b92774736a67dbf"
                    >
                      0x164b6f3f35d98214a592e4ba7b92774736a67dbf
                    </a>
                  </p>
                  <a className="btn_primary home_page_3_btn" href="#">
                    Buy BBT
                    <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 wow fadeInRight">
                <div className="crators_3_img1 crators_3_img2">
                  <img
                    src="assets/img/bbt-native-currency-for-transferring-value.png"
                    alt=" BBT – Native Currency For Transferring Value"
                    className="img-fluid w-100 w-buy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
